document.addEventListener('DOMContentLoaded', function () {
  const form = document.querySelector('.form-contact');
  let alertField = form.querySelector('#alert');

  form.addEventListener('submit', function (event) {
    console.log('form submit');
    event.preventDefault();

    if (!form.checkValidity()) {
      alertField.innerHTML = 'Vyplňte prosím všechna pole formuláře.';
      alertField.classList.add('opacity-100');
      alertField.classList.remove('hidden');
      return;
    }
    if (!form.querySelector('input[type="checkbox"]').checked) {
      alertField.innerHTML = 'Pro odeslání formuláře je nutné souhlasit se zpracováním osobních údajů.';
      alertField.classList.add('opacity-100');
      alertField.classList.remove('hidden');
      return;
    }
    if (form.querySelector('input#captcha').value !== 'netion') {
      console.log(form.querySelector('input#captcha').value);
      alertField.innerHTML = 'Opište prosím slovo netion do pole pro ověření.';
      alertField.classList.add('opacity-100');
      alertField.classList.remove('hidden');
      return;
    }
    if (form.querySelector('input#phone').value.length > 1 && form.querySelector('input#phone').value.length < 9) {
      alertField.innerHTML = 'Zadejte prosím platné telefonní číslo.';
      alertField.classList.add('opacity-100');
      alertField.classList.remove('hidden');
      return;
    }

    const formData = new FormData(form);

    fetch('send_email.php', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log(data);
          alertField.classList.add('opacity-100');
          alertField.classList.remove('hidden');
          alertField.innerHTML = 'Děkujeme za odeslání formuláře. Brzy se vám ozveme.';
          form.reset();
        } else {
          console.log(data);
          alert('Odeslání emailu selhalo. Zkuste to prosím znovu.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Chyba při odeslání. Zkuste to prosím znovu.');
      });
  });
});